import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../app.service'
import { MatSnackBar } from '@angular/material';
// import { AuthService } from '../../core/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  authToken: any;
  token: any;
  forgotPasswordPage: boolean;
  userForm: FormGroup;
  formErrors = {
    'newPassword': '',
    'ConfirmPassword': ''
  };
  validationMessages = {
    'newPassword': {
      'required': 'please enter new password',
      'pattern': 'The password must contain numbers and letters',
      'minlength': 'Please enter more than 4 characters',
      'maxlength': 'Please enter less than 25 characters',
    },
    'ConfirmPassword': {
      'required': 'please enter confirm password',
    }
  };

  constructor(private router: Router,private fb: FormBuilder, private _apiService: ApiService, private _snackbar: MatSnackBar) {}

  ngOnInit() {
    this.buildForm();
    this.forgotPasswordPage = false;
    var url = this.router.url;
    if(url){
    	console.log(url, "this.token")
    	let access_token = url.split("/reset?token=");
	    this.token = access_token[1];
	    console.log(this.token, "this.token");
    }
  }

  buildForm() {
	    this.userForm = this.fb.group({
	      'newPassword': ['', [
	        Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
	        Validators.minLength(6),
	        Validators.maxLength(25)
	      ]
	      ],
	      'ConfirmPassword': ['', [
	        Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
	        Validators.minLength(6),
	        Validators.maxLength(25)
	      ]
	      ],
	    });
  }

   resetPassword(data){
	  	var obj = {
	  		"newPassword" : this.userForm.value.newPassword,
			"token" : this.token
	  	}

		if(this.userForm.value.newPassword == this.userForm.value.ConfirmPassword){
			this._apiService.resetPassword(obj).subscribe(res => {
	        if(res.status == 'OK'){
	          this._snackbar.open('Reset Password Successfully !', 'X')
	          this.router.navigate(['/login']);
	          console.log(this.authToken, "this.authToken")
	        }else{
	          console.log(res.data)
	        }
	      }, err => {
	        console.log(err)
	        this._snackbar.open(err.error.message, 'X')
	      });
		}else{
			this._snackbar.open('Confirm Password Do Not Match !', 'X', { duration: 5000})
		}
   }

}

