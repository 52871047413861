import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject }    from 'rxjs';
import { RouterModule, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})


// view data user service from feature table (usertable) to update user data transfer 
export class TableserviceService {

	private messageSource = new BehaviorSubject(Object);
    currentMessage = this.messageSource.asObservable();

	public _subject = new Subject<object>();
	public event = this._subject.asObservable();
	public publish(data: any) {
	    this._subject.next(data);
	    console.log(data, "data")
	}
	getData(): Observable<any> {
        return this._subject.asObservable();
    }
  constructor(private _router: Router) { }

   changeMessage(message: object) {
    // this.messageSource.next(message)
  }

}
